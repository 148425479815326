<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div class="text-center text-white pt-4">
          Dashboard
        </div>
      </div>
      <div class="card-body justify-content-center" style="display:grid">
        <b-row class="mb-3">
          <div class="col-6">
            <div class="text-primary text-center">
              <router-link :to="{ name: 'profile' }">
                <b-iconstack font-scale="5">
                  <b-icon stacked icon="square"></b-icon>
                  <b-icon stacked icon="person"></b-icon>
                </b-iconstack>
              </router-link>
              <p>Profile</p>
            </div>
          </div>
          <div class="col-6">
            <div class="text-primary text-center">
              <span @click="logout">
                <b-iconstack font-scale="5">
                  <b-icon stacked icon="square"></b-icon>
                  <b-icon stacked icon="file-lock"></b-icon>
                </b-iconstack>
              </span>
              <p>Logout</p>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-6" v-if="userIntent.slug in farmerIntent">
            <div class="text-primary text-center">
              <router-link :to="{ name: 'farm' }">
                <b-iconstack font-scale="5">
                  <b-icon stacked icon="square"></b-icon>
                  <b-icon stacked icon="flower2"></b-icon>
                </b-iconstack>
              </router-link>
              <p>Farm</p>
            </div>
          </div>
          <div class="col-6" v-if="userIntent.slug === 'ask-expert-advice'">
            <div class="text-primary">
              <router-link :to="{ name: 'ask-expert' }">
                <b-iconstack font-scale="5">
                  <b-icon stacked icon="square"></b-icon>
                  <b-icon stacked icon="question"></b-icon>
                </b-iconstack>
              </router-link>
              <p>Get Advice</p>
            </div>
          </div>
          <div class="col-6" v-if="userIntent.slug === 'sell-produce'">
            <div class="text-primary">
              <router-link :to="{ name: 'produce-to-sold' }">
                <b-iconstack font-scale="5">
                  <b-icon stacked icon="square"></b-icon>
                  <b-icon stacked icon="tree"></b-icon>
                </b-iconstack>
              </router-link>
              <p>Produces(sell)</p>
            </div>
          </div>
          <div class="col-6" v-if="userIntent.slug === 'buy-produce'">
            <div class="text-primary">
              <router-link :to="{ name: 'produce-to-bought' }">
                <b-iconstack font-scale="5">
                  <b-icon stacked icon="square"></b-icon>
                  <b-icon stacked icon="tree"></b-icon>
                </b-iconstack>
              </router-link>
              <p>Produces(Buy)</p>
            </div>
          </div>
          <div class="col-6" v-if="userIntent.slug === 'sell-land'">
            <div class="text-primary">
              <router-link :to="{ name: 'new-land' }">
                <b-iconstack font-scale="5">
                  <b-icon stacked icon="square"></b-icon>
                  <b-icon stacked icon="house-door"></b-icon>
                </b-iconstack>
              </router-link>
              <p>Add Land</p>
            </div>
          </div>
          <div class="col-6" v-if="userIntent.slug === 'sell-land'">
            <div class="text-primary">
              <router-link :to="{ name: 'land-for-sell-list' }">
                <b-iconstack font-scale="5">
                  <b-icon stacked icon="square"></b-icon>
                  <b-icon stacked icon="house-door"></b-icon>
                </b-iconstack>
              </router-link>
              <p>Lands(list)</p>
            </div>
          </div>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {
      showDismissibleAlert: false
    };
  },
  computed: {
    ...mapState({
      loggedInUser: "user",
      userIntent: "userIntent",
      farmerIntent: "farmerIntentList"
    })
  },
  methods: {
    logout: function() {
      this.$store.commit("unauthUser");
      this.redirectAfterLogout();
    },
    redirectAfterLogout: function() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style scoped></style>
