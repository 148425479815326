<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div
          @click="$router.push({ name: 'buy-or-sell-produce' })"
          style="cursor:pointer"
          class="mb-3"
        >
          <b-icon icon="arrow-left-circle" scale="1.5" variant="white"></b-icon>
          <span class="ml-3 text-white">Back</span>
        </div>
        <div class="text-center text-white">
          Where are you based, and what would you like to buy? Note, you can
          select multiple products.
        </div>
      </div>
      <div class="card-body">
        <div>
          <b-form-group
            id="input-group-3"
            label="Location"
            label-for="input-3"
            class="text-left"
          >
            <b-form-input
              id="input-3"
              v-model="location"
              required
            ></b-form-input>
          </b-form-group>
          <produce-list
            @addProduce="saveSelectedProduct"
            :preSelected="[]"
          ></produce-list>
          <b-button
            href="#"
            variant="primary"
            @click="saveAns"
            class="text-left  "
            >Next</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProduceList from "@/components/partials/ProduceList";
export default {
  name: "BuyProduce",
  components: {
    ProduceList
  },
  data() {
    return {
      answQuest: [],
      location: "",
      province: "",
      selectedProd: []
    };
  },
  methods: {
    saveAns: function() {
      let answ = {};
      answ["type"] = "buy-produce";
      answ["data"] = {};
      answ["data"]["location"] = this.location;
      answ["data"]["produces"] = this.selectedProd;
      this.$router.push({ name: "final-step", params: answ });
    },
    saveSelectedProduct: function(value) {
      this.selectedProd.push(value);
    }
  }
};
</script>

<style scoped></style>
