import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isAuth: localStorage.getItem("isAuth"),
    tokenBearer: localStorage.getItem("token"),
    user: {},
    userIntent: {},
    farmerIntentList: {
      "sell-produce": 2,
      "get-inputs": 6,
      "ask-expert-advice": 5
    }
  },
  getters: {},
  mutations: {
    authUser(state, token) {
      state.tokenBearer = token;
      localStorage.setItem("token", token);
      state.isAuth = true;
    },

    unauthUser(state) {
      localStorage.removeItem("token");
      localStorage.setItem("isAuth", false);
      state.isAuth = false;
      axios
        .get("logout")
        .then(res => {
          console.log(res);
          // this.store.commit("getUserIntent");
        })
        .catch(err => {
          console.log(err);
        });
    },

    isAuth: function(state) {
      if (state.tokenBearer) {
        state.tokenBearer = localStorage.getItem("token");
        // console.log(localStorage.getItem("token"));
        axios
          .get("is_auth", {
            headers: {
              Authorization: "Bearer " + state.tokenBearer
            }
          })
          .then(res => {
            state.user = res.data;
            localStorage.setItem("isAuth", true);
            state.isAuth = true;
            // this.store.commit("getUserIntent");
            console.log("logged in");
          })
          .catch(err => {
            state.isAuth = false;
            localStorage.removeItem("token");
            console.log(err);
          });
      } else {
        localStorage.removeItem("token");
        localStorage.setItem("isAuth", false);
        state.isAuth = false;
      }
    },

    getUserIntent: function(state) {
      {
        axios
          .get("user", {
            headers: { Authorization: "Bearer " + state.tokenBearer }
          })
          .then(res => {
            state.userIntent = res.data[0] ?? {};
            console.log("happening");
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    updateUserProfile: function(state, data) {
      axios.post(
        "user/update",
        {
          full_name: data.full_name,
          email: data.email,
          phone_number: data.phone_number,
          location: data.city
        },
        { headers: { Authorization: "Bearer " + state.tokenBearer } }
      );
    }
  },
  actions: {},
  modules: {}
});
