<template>
  <div>
    <b-row class="justify-content-center">
      <div class="col-md-3 col-12">
        <b-card title="Login" img-top tag="article" class="mb-2">
          <b-form-group
            id="input-group-3"
            label="Email"
            label-for="input-3"
            class="text-left"
          >
            <b-form-input
              type="email"
              id="input-3"
              required
              v-model="form.email"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Password"
            label-for="input-4"
            class="text-left"
          >
            <b-form-input
              type="password"
              id="input-4"
              required
              v-model="form.password"
            ></b-form-input>
          </b-form-group>
          <div>
            <b-alert
              show
              variant="danger"
              v-for="(error, x) in validationError"
              :key="x"
            >
              {{ error[0] }}
            </b-alert>
          </div>
          <b-button
            href="#"
            variant="primary"
            class="text-left  mb-3 mr-2"
            @click="formLogin"
            >Login</b-button
          >
          <b-button
            href="#"
            variant="primary"
            class="text-left  mb-3"
            :to="{ name: 'step-one' }"
            >Sign up</b-button
          >
          <p>Or Login with</p>
          <div>
            <b-button
              @click="redirect('facebook')"
              style="background-color: #3b5999"
              class="mr-2 text-white"
            >
              <svg
                enable-background="new 0 0 24 24"
                height="20"
                viewBox="0 0 24 24"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
                style="padding-bottom: 5px"
              >
                <path
                  d="m21 0h-18c-1.655 0-3 1.345-3 3v18c0 1.654 1.345 3 3 3h18c1.654 0 3-1.346 3-3v-18c0-1.655-1.346-3-3-3z"
                  fill="#3b5999"
                />
                <path
                  d="m16.5 12v-3c0-.828.672-.75 1.5-.75h1.5v-3.75h-3c-2.486 0-4.5 2.014-4.5 4.5v3h-3v3.75h3v8.25h4.5v-8.25h2.25l1.5-3.75z"
                  fill="#fff"
                />
              </svg>
              <small>Login with Facebook</small>
            </b-button>
            <b-button variant="light" @click="redirect('google')">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="20"
                height="20"
                viewBox="0 0 512 512"
                style="enable-background:new 0 0 512 512;"
                xml:space="preserve"
              >
                <path
                  style="fill:#FBBB00;"
                  d="M113.47,309.408L95.648,375.94l-65.139,1.378C11.042,341.211,0,299.9,0,256
	c0-42.451,10.324-82.483,28.624-117.732h0.014l57.992,10.632l25.404,57.644c-5.317,15.501-8.215,32.141-8.215,49.456
	C103.821,274.792,107.225,292.797,113.47,309.408z"
                />
                <path
                  style="fill:#518EF8;"
                  d="M507.527,208.176C510.467,223.662,512,239.655,512,256c0,18.328-1.927,36.206-5.598,53.451
	c-12.462,58.683-45.025,109.925-90.134,146.187l-0.014-0.014l-73.044-3.727l-10.338-64.535
	c29.932-17.554,53.324-45.025,65.646-77.911h-136.89V208.176h138.887L507.527,208.176L507.527,208.176z"
                />
                <path
                  style="fill:#28B446;"
                  d="M416.253,455.624l0.014,0.014C372.396,490.901,316.666,512,256,512
	c-97.491,0-182.252-54.491-225.491-134.681l82.961-67.91c21.619,57.698,77.278,98.771,142.53,98.771
	c28.047,0,54.323-7.582,76.87-20.818L416.253,455.624z"
                />
                <path
                  style="fill:#F14336;"
                  d="M419.404,58.936l-82.933,67.896c-23.335-14.586-50.919-23.012-80.471-23.012
	c-66.729,0-123.429,42.957-143.965,102.724l-83.397-68.276h-0.014C71.23,56.123,157.06,0,256,0
	C318.115,0,375.068,22.126,419.404,58.936z"
                />
              </svg>
              Login with Gmail
            </b-button>
          </div>
        </b-card>
      </div>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import routeGuardMixins from "@/mixins/routeGuardMixins";

export default {
  name: "Login",
  mixins: [routeGuardMixins],
  data() {
    return {
      validationError: [],
      form: {
        email: "",
        password: "",
        device: "web_client"
      }
    };
  },
  created() {
    if (this.$route.query.token) {
      this.validationError = "";
      var vm = this;
      axios
        .post("login/oauth", {
          token: vm.$route.query.token,
          provider: vm.$route.query.provider
        })
        .then(res => {
          console.log("res");
          // return;
          vm.authUser(res.data);
          console.log(res);
        })
        .catch(err => {
          vm.validationErrorpush([err.response.data.message]);
        });
    }
  },
  methods: {
    redirect(provider) {
      location.replace(
        this.$apiHost + "/auth/oauth/redirect/" + provider + "/login"
      );
    },
    formLogin: function() {
      var vm = this;
      this.validationError = [];
      axios
        .post("login/form", this.form)
        .then(res => {
          vm.authUser(res.data);
        })
        .catch(err => {
          console.log(err);
          vm.validationError = Array([err.response.data.message]);
          console.log(err.response.data.message);
        });
    },
    authUser: function(token) {
      this.$store.commit("authUser", token);
      this.$store.commit("isAuth");
      this.redirectAfterLogin();
    },
    redirectAfterLogin() {
      this.$router.push({ name: "dashboard" });
    }
  },

  computed: {
    ...mapState({
      isLoggedIn: "isAuth"
    })
  }
};
</script>

<style scoped></style>
