import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import "./app.scss";

new Date().getDate() + 1;
new Date().getDate() + 1;
new Date().getDate() + 1;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

/// This is really bad... clean it up please
//Todo : to be refactored
Vue.prototype.$apiHost =
  process.env.NODE_ENV === "development"
    ? "http://lara-5050.test"
    : "https://dashboard.5050.africa";
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? "http://lara-5050.test/api"
    : "https://dashboard.5050.africa/api";

// axios.defaults.headers
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
