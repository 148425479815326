<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div
          @click="$router.push({ name: 'step-one' })"
          style="cursor:pointer"
          class="mb-3"
        >
          <b-icon icon="arrow-left-circle" scale="1.5" variant="white"></b-icon>
          <span class="ml-3 text-white">Back</span>
        </div>
        <div class="text-center text-white">
          Where is the land?
        </div>
      </div>
      <div class="card-body">
        <b-form-group
          id="input-group-3"
          label="Location"
          label-for="input-3"
          class="text-left"
        >
          <b-form-input id="input-3" required v-model="location"></b-form-input>
        </b-form-group>
        <b-button
          href="#"
          variant="primary"
          @click="updateQuestionAnswered('final-step')"
          class="text-left  "
          >Next</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuyLand",
  data() {
    return {
      location: ""
    };
  },
  methods: {
    updateQuestionAnswered: function(name) {
      if (name === "final-step") {
        let answ = {};
        answ["type"] = "buy-land";
        answ["data"] = {};
        answ["data"]["location"] = this.location;
        this.$router.push({ name: name, params: answ });
      } else {
        this.$router.push({ name: name });
      }
    }
  }
};
</script>

<style scoped></style>
