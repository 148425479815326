<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <!--        <div @click="$router.push({ name: 'step-one' })" style="cursor:pointer">-->
        <!--          <b-icon icon="arrow-left-circle" scale="1.5" variant="white"></b-icon>-->
        <!--          <span class="ml-3 text-white">Back</span>-->
        <!--        </div>-->
        <div class="text-center text-white pt-4">
          Profile
        </div>
      </div>
      <div class="card-body">
        <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
          Success!
        </b-alert>
        <form action="#" method="post" @submit.prevent="updateUserInfo">
          <b-form-group
            id="input-group-1"
            label="Name"
            label-for="input-1"
            class="text-left"
          >
            <b-form-input
              id="input-2"
              v-model="loggedInUser.full_name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Email"
            label-for="input-3"
            class="text-left"
          >
            <b-form-input
              id="input-3"
              required
              v-model="loggedInUser.email"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Phone number"
            label-for="input-5"
            class="text-left"
          >
            <b-form-input
              type="number"
              id="input-4"
              v-model="loggedInUser.phone_number"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Location"
            label-for="input-1"
            class="text-left"
          >
            <b-form-input
              id="input-field-location"
              v-model="loggedInUser.city"
              required
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary" class="text-left  mb-3"
            >Update</b-button
          >
        </form>
      </div>
    </div>
    <div class="mt-5 mb-5">
      <b-alert variant="success" show dismissible v-show="success"
        >Information updated</b-alert
      >
    </div>
    <b-card
      title="More information"
      img-top
      tag="article"
      class="mb-2 mt-5"
      v-if="isBuyingProduce"
    >
      <form action="#" @submit.prevent="setAttribute">
        <b-form-group
          id="input-is-experienced"
          label="Experience"
          label-for="input-3"
          class="text-left"
        >
          <b-form-radio
            v-model="is_experienced"
            value="1"
            id="input-field-experience"
            required
            >Yes</b-form-radio
          >
          <b-form-radio
            v-model="is_experienced"
            value="0"
            id="input-field-experience-no"
            required
            >No</b-form-radio
          >
        </b-form-group>
        <b-form-group
          id="input-is-experience"
          label="Highest qualification"
          label-for="input-5"
          class="text-left"
        >
          <b-form-input
            type="text"
            id="input-field-experience"
            v-model="highest_qualification"
            required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" class="text-left  mb-3"
          >Update</b-button
        >
      </form>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      location: "",
      highest_qualification: "",
      is_experienced: "",
      success: false,
      attribute: "",
      isBuyingProduce: false,
      showDismissibleAlert: false
    };
  },
  created() {
    // var vm = this;
  },
  computed: {
    ...mapState({
      token: "tokenBearer",
      loggedInUser: "user",
      profile: "userIntent"
    })
  },
  methods: {
    userAttribute: function() {
      var vm = this;
      axios
        .get("user/more-info", {
          headers: { Authorization: "Bearer " + this.token }
        })
        .then(res => {
          (vm.location = res.data.location),
            (vm.is_experienced = res.data.is_experienced),
            (vm.highest_qualification = res.data.highest_qualification),
            console.log(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    setAttribute: function() {
      var vm = this;
      axios
        .post(
          "user/add/more-info",
          {
            location: vm.location,
            is_experienced: vm.is_experienced,
            highest_qualification: vm.highest_qualification
          },
          {
            headers: { Authorization: "Bearer " + this.token }
          }
        )
        .then(res => {
          this.success = true;
          console.log(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateUserInfo: function() {
      this.$store.commit("updateUserProfile", this.loggedInUser);
      this.showDismissibleAlert = true;
    }
  },
  watch: {
    profile: function(value) {
      if (value.pivot.user_intent_id == 1) {
        this.isBuyingProduce = true;
        this.userAttribute();
      }
    }
  }
};
</script>

<style scoped></style>
