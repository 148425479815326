<template>
  <div>
    <b-row class="justify-content-center">
      <div class="col-md-5 col-12">
        <router-view></router-view>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import store from "@/store/index";

export default {
  name: "HomeLayout",
  computed: {
    ...mapState({
      isLoggedIn: "isAuth"
    })
  }
};
</script>

<style scoped></style>
