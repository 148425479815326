<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div
          @click="$router.push({ name: 'step-one' })"
          style="cursor:pointer"
          class="mb-3"
        >
          <b-icon icon="arrow-left-circle" scale="1.5" variant="white"></b-icon>
          <span class="ml-3 text-white">Back</span>
        </div>
        <div class="text-center text-white">
          Do you have produce you want to sell, or are you looking to buy
          produce?
        </div>
      </div>
      <div class="card-body">
        <div class="text-center">
          <b-button
            href="#"
            variant="primary"
            class="d-block mb-4"
            @click="updateQuestionAnswered('buy-produce')"
            >Buying Produce
          </b-button>
          <b-button
            href="#"
            variant="primary"
            class="d-block mb-4"
            @click="updateQuestionAnswered('final-step')"
            >Selling Produce</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuyOrSellProduce",
  methods: {
    updateQuestionAnswered: function(name) {
      if (name === "final-step") {
        let answ = {};
        answ["type"] = "sell-produce";
        this.$router.push({ name: name, params: answ });
      } else {
        this.$router.push({ name: name });
      }
    }
  }
};
</script>

<style scoped></style>
