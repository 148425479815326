<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div
          @click="$router.push({ name: 'step-one' })"
          style="cursor:pointer"
          class="mb-3"
        >
          <b-icon icon="arrow-left-circle" scale="1.5" variant="white"></b-icon>
          <span class="ml-3 text-white">Back</span>
        </div>
        <div class="text-center text-white">
          5050 Africa helps farmers and potential farmers get access to land. If
          you are a farmer looking for land to buy or lease, click “I Need
          Land”; or if you have land available that you are not using and want
          to maximise value, click “I Have Land”
        </div>
      </div>
      <div class="card-body">
        <div class="text-center">
          <b-button
            href="#"
            variant="primary"
            class="d-block mb-4"
            @click="updateQuestionAnswered('buy-land')"
          >
            I Need Land
          </b-button>
          <b-button
            href="#"
            variant="primary"
            class="d-block mb-4"
            @click="updateQuestionAnswered('final-step')"
            >I Have Land</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuyOrSellLands",
  methods: {
    updateQuestionAnswered: function(name) {
      let answ = {};
      if (name === "final-step") {
        answ["type"] = "sell-land";
        this.$router.push({ name: name, params: answ });
      } else {
        answ["type"] = "buy-land";
        this.$router.push({ name: name, params: answ });
      }
    }
  }
};
</script>

<style scoped></style>
