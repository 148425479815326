<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div @click="$router.push({ name: 'profile' })" style="cursor:pointer">
          <b-icon icon="arrow-left-circle" scale="1.5" variant="white"></b-icon>
          <span class="ml-3 text-white">Back</span>
        </div>
        <div class="text-center text-white pt-4">
          Farm information
        </div>
      </div>
      <div class="card-body">
        <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
          farm information updated successfully
        </b-alert>
        <form action="#" @submit.prevent="UpdateFarmDetails">
          <b-form-group
            id="input-location"
            label="Farm type"
            label-for="input-1"
            class="text-left"
          >
            <b-form-select
              id="input-field-location"
              v-model="farm.type"
              :options="farm_types"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Land Ownership"
            label-for="input-1"
            class="text-left"
          >
            <b-form-select
              id="input-field-location"
              v-model="farm.land_owned_by"
              :options="land_ownership"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group id="input-56" label="Personnel" class="text-left">
            <multiselect
              track-by="id"
              label="name"
              v-model="selectedPersonnel"
              :options="personnel"
              :multiple="true"
            ></multiselect>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Land Size(Hectares)"
            label-for="input-1"
            class="text-left"
          >
            <b-form-input
              type="number"
              id="input-field-location"
              v-model="farm.land_size"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Land Total Ploughed"
            label-for="input-1"
            class="text-left"
          >
            <b-form-input
              type="number"
              id="input-field-location"
              v-model="farm.land_total_ploughed"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Location"
            label-for="input-1"
            class="text-left"
          >
            <b-form-input
              type="text"
              id="input-field-location"
              v-model="farm.location_town"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Land Soil Test"
            class="text-left"
          >
            <b-form-radio v-model="farm.land_soil_test" :value="1" required
              >Yes</b-form-radio
            >
            <b-form-radio v-model="farm.land_soil_test" :value="0" required
              >No</b-form-radio
            >
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Water Sources"
            label-for="input-1"
            class="text-left"
          >
            <multiselect
              track-by="id"
              label="name"
              v-model="selectedWaterSources"
              :options="water_sources"
              :multiple="true"
            ></multiselect>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Borehole Water"
            class="text-left"
          >
            <b-form-radio value="1" v-model="farm.borehole_water">
              Yes</b-form-radio
            >
            <b-form-radio value="0" v-model="farm.borehole_water">
              No</b-form-radio
            >
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Water irrigation"
            class="text-left"
          >
            <b-form-radio value="1" v-model="farm.water_irrigation">
              Yes</b-form-radio
            >
            <b-form-radio value="0" v-model="farm.water_irrigation">
              No</b-form-radio
            >
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Water rights"
            class="text-left"
          >
            <b-form-radio value="1" v-model="farm.water_rights">
              Yes</b-form-radio
            >
            <b-form-radio value="0" v-model="farm.water_rights">
              No</b-form-radio
            >
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Water Storage"
            class="text-left"
          >
            <b-form-radio value="1" v-model="farm.water_storage">
              Yes</b-form-radio
            >
            <b-form-radio value="0" v-model="farm.water_storage">
              No</b-form-radio
            >
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Electricity"
            label-for="input-1"
            class="text-left"
          >
            <multiselect
              track-by="id"
              label="name"
              v-model="selectedEletrictySources"
              :options="electricity"
              :multiple="true"
            ></multiselect>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Land status"
            label-for="input-1"
            class="text-left"
          >
            <b-form-select
              id="input-field-location"
              v-model="farm.land_status"
              :options="land_status"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group id="input-location" label="Tractor" class="text-left">
            <b-form-radio v-model="farm.tractor" value="1" required
              >Yes</b-form-radio
            >
            <b-form-radio v-model="farm.tractor" value="0" required
              >No</b-form-radio
            >
          </b-form-group>
          <b-form-group label="Transport" label-for="input-1" class="text-left">
            <b-form-radio v-model="farm.transport" value="1" required
              >Yes</b-form-radio
            >
            <b-form-radio v-model="farm.transport" value="0" required
              >No</b-form-radio
            >
          </b-form-group>
          <b-form-group label="Warehouse" label-for="input-1" class="text-left">
            <b-form-radio v-model="farm.warehouse" value="1" required
              >Yes</b-form-radio
            >
            <b-form-radio v-model="farm.warehouse" value="0" required
              >No</b-form-radio
            >
          </b-form-group>
          <b-form-group label="Other funding" class="text-left">
            <b-form-select
              v-model="farm.other_funding"
              :options="other_funding"
              required
            ></b-form-select>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Government support"
            label-for="input-1"
            class="text-left"
          >
            <b-form-select
              id="input-field-location"
              v-model="farm.government_support"
              :options="government_support"
              required
            ></b-form-select>
          </b-form-group>
          <b-button type="submit" variant="primary" class="text-left  mb-3"
            >Update</b-button
          >
        </form>
      </div>
    </div>
    <link
      rel="stylesheet"
      href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css"
    />
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

export default {
  name: "FarmProfile",
  components: {
    Multiselect
  },
  data() {
    return {
      selectedPersonnel: [],
      selectedEletrictySources: [],
      selectedWaterSources: [],
      showDismissibleAlert: false,
      farm: {
        type: "",
        land_owned_by: "",
        personnel: [],
        land_details: "",
        land_size: "",
        location_town: "",
        land_total_ploughed: "",
        land_soil_test: "",
        water: "",
        electricity: "",
        land_status: "",
        borehole_water: "",
        water_irrigation: "",
        water_rights: "",
        water_storage: "",
        government_support: "",
        other_funding: "",
        water_sources: "",
        transport: "",
        tractor: "",
        warehouse: ""
      },
      farm_types: ["pvt company", "co-op", "personal", "family"],
      land_ownership: ["Me", "Family", "Company", "Leased", "Tribal"],
      personnel: [
        { name: "labourers", id: 1 },
        { name: "agronomist", id: 2 },
        { name: "other", id: 3 }
      ],
      land_details: ["size", "total ploughed", "soil test"],
      water: [
        "rain",
        " river",
        "dam",
        "borehole",
        "irrigation",
        " water rights",
        "water test",
        "water storage"
      ],
      electricity: [
        { name: "grid", id: 1 },
        { name: "solar", id: 2 },
        { name: "generator", id: 3 }
      ],
      land_status: ["fenced", "debushed"],
      government_support: [
        "CEDA",
        "NDB",
        "YDF",
        "Self",
        "farmers fund",
        "LEA",
        "ISPAAD"
      ],
      other_funding: [
        "FNB",
        "Stanbic",
        "Absa",
        "NDB",
        "Private Investors",
        "Self Funded"
      ],
      water_sources: [
        { id: 1, name: "river" },
        { id: 2, name: "rain fed" },
        { id: 3, name: "dam" }
      ]
    };
  },
  computed: {
    ...mapState({
      token: "tokenBearer"
    })
  },
  mounted() {
    var vm = this;
    axios
      .get("/user/farm", {
        headers: { Authorization: "Bearer " + this.token }
      })
      .then(res => {
        vm.farm = res.data;
      });
  },
  watch: {
    "farm.electricity_sources"(values) {
      this.selectedEletrictySources = values;
    },

    "farm.water_sources"(values) {
      this.selectedWaterSources = values;
    },

    "farm.personnel_types"(values) {
      this.selectedPersonnel = values;
    },

    selectedPersonnel(values) {
      this.farm.personnel = values;
    },

    selectedEletrictySources(values) {
      this.farm.electricity = values;
    },

    selectedWaterSources(values) {
      this.farm.water_sources = values;
    }
  },
  methods: {
    UpdateFarmDetails: function() {
      var vm = this;
      axios
        .post("/user/farm/update", this.form, {
          headers: { Authorization: "Bearer " + this.token }
        })
        .then(res => {
          console.log(res);
        })
        .then(err => {
          vm.showDismissibleAlert = true;
          console.log(err);
        });
    }
  }
};
</script>

<style scoped></style>
