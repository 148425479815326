import { render, staticRenderFns } from "./BuyOrSellProduce.vue?vue&type=template&id=3685bbd3&scoped=true&"
import script from "./BuyOrSellProduce.vue?vue&type=script&lang=js&"
export * from "./BuyOrSellProduce.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3685bbd3",
  null
  
)

export default component.exports