<template>
  <b-container>
    <b-row class="justify-content-center">
      <div class="col-md-4">
        <welcome></welcome>
      </div>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import Welcome from "@/views/Welcome.vue";
import routeGuardMixins from "@/mixins/routeGuardMixins";

export default {
  name: "Home",
  mixins: [routeGuardMixins],
  components: { Welcome }
};
</script>
