<template>
  <div>
    <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
      Produce list updated
    </b-alert>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div @click="$router.push({ name: 'profile' })" style="cursor:pointer">
          <b-icon icon="arrow-left-circle" scale="1.5" variant="white"></b-icon>
          <span class="ml-3 text-white">Back</span>
        </div>
        <div class="text-center text-white pt-4">
          Profile
        </div>
      </div>
      <div class="card-body">
        <produce-list
          @removeProduce="removeSelectedProduct"
          @addProduce="addSelectedProduct"
          :preSelected="selectedProduct"
        ></produce-list>
        <b-form inline>
          <div
            v-for="produce in selectedProduct"
            class="text-left mb-3 col-md-12"
            :key="produce.name"
          >
            <b-form-input
              v-model="produce.name"
              class="mr-2 "
              disabled
            ></b-form-input>
            <datetime
              class="mr-2 form-control"
              type="datetime"
              :input-class="{
                'f-size': true,
                'border-0': true,
                'mt-n1': true
              }"
              required
              v-model="produce.date_required"
              placeholder="Date required"
            ></datetime>
            <b-form-input
              class="f-size"
              v-model="produce.quantity_required"
              required
              placeholder="Quantity required"
            ></b-form-input>
          </div>
        </b-form>
        <b-button
          type="submit"
          variant="primary"
          @click="updateUserProduce"
          class="text-left  mb-3"
          >Update</b-button
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-control {
  font-size: 0.9rem;
}
</style>
<script>
import axios from "axios";
import { mapState } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import ProduceList from "@/components/partials/ProduceList";

export default {
  name: "ProduceToBeBought",
  components: {
    Datetime,
    ProduceList
  },
  data() {
    return {
      selectedProduct: [],
      showDismissibleAlert: false
    };
  },
  created() {
    axios
      .get("user/produces", {
        headers: { Authorization: "Bearer " + this.token }
      })
      .then(res => {
        this.selectedProduct = this.formatSelectedProduces(res.data);
      });
  },
  computed: {
    ...mapState({
      token: "tokenBearer"
    })
  },
  methods: {
    updateUserProduce: function() {
      console.log(this.selectedProduct);
      var vm = this;
      axios
        .post(
          "user/produces/update",
          {
            produces: vm.selectedProduct
          },
          { headers: { Authorization: "Bearer " + this.token } }
        )
        .then(res => {
          vm.showDismissibleAlert = true;
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    },
    addSelectedProduct: function(produce) {
      this.selectedProduct.push({
        id: produce.id,
        name: produce.name,
        date_required: "",
        quantity_required: ""
      });
    },
    removeSelectedProduct: function(produce) {
      let selected = this.selectedProduct.findIndex(
        el => el.produce_id === produce.id
      );
      this.selectedProduct.splice(selected, 1);
    },
    formatSelectedProduces(values) {
      let selected = [];
      for (let val of values) {
        selected.push({
          id: val.id,
          name: val.name,
          quantity_required: val.pivot.quantity_required,
          date_required: val.pivot.date_required
        });
      }
      return selected;
    }
  }
};
</script>

<style scoped></style>
