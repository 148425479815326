<template>
  <div class="container">
    <b-row>
      <div>
        <h1>
          <span>Privacy Policy</span>
        </h1>
        <p><span>Last updated: May 13, 2021</span></p>
        <p>
          <span
            >This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.</span
          >
        </p>
        <p>
          <span
            >We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy. This Privacy
            Policy has been created with the help of the </span
          ><span
            ><a
              href="https://www.google.com/url?q=https://www.privacypolicies.com/privacy-policy-generator/&amp;sa=D&amp;source=editors&amp;ust=1621105765928000&amp;usg=AOvVaw3E8LORHfigl_3pd6xdoU78"
              >Privacy Policy Generator</a
            ></span
          ><span>.</span>
        </p>
        <h1>
          <span>Interpretation and Definitions</span>
        </h1>
        <h2>
          <span>Interpretation</span>
        </h2>
        <p>
          <span
            >The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.</span
          >
        </p>
        <h2>
          <span>Definitions</span>
        </h2>
        <p>
          <span>For the purposes of this Privacy Policy:</span>
        </p>
        <ul>
          <li>
            <span
              >Account means a unique account created for You to access our
              Service or parts of our Service.</span
            >
          </li>
          <li>
            <span
              >Company (referred to as either &quot;the Company&quot;,
              &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
              Agreement) refers to 5050 Africa , Shop 13A Tati River Mall.</span
            >
          </li>
          <li>
            <span
              >Cookies are small files that are placed on Your computer, mobile
              device or any other device by a website, containing the details of
              Your browsing history on that website among its many uses.</span
            >
          </li>
          <li>
            <span>Country refers to: Botswana</span>
          </li>
          <li>
            <span
              >Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.</span
            >
          </li>
          <li>
            <span
              >Personal Data is any information that relates to an identified or
              identifiable individual.</span
            >
          </li>
          <li>
            <span>Service refers to the Website.</span>
          </li>
          <li>
            <span
              >Service Provider means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to
              perform services related to the Service or to assist the Company
              in analyzing how the Service is used.</span
            >
          </li>
          <li>
            <span
              >Third-party Social Media Service refers to any website or any
              social network website through which a User can log in or create
              an account to use the Service.</span
            >
          </li>
          <li>
            <span
              >Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page
              visit).</span
            >
          </li>
          <li>
            <span>Website refers to 5050 Africa, accessible from </span
            ><span
              ><a
                href="https://www.google.com/url?q=https://app.5050.africa/&amp;sa=D&amp;source=editors&amp;ust=1621105765932000&amp;usg=AOvVaw3M1Hh1TIuDB6V2u1G7icwi"
                >https://app.5050.africa/</a
              ></span
            >
          </li>
          <li>
            <span
              >You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.</span
            >
          </li>
        </ul>
        <h1>
          <span>Collecting and Using Your Personal Data</span>
        </h1>
        <h2>
          <span>Types of Data Collected</span>
        </h2>
        <h3>
          <span>Personal Data</span>
        </h3>
        <p>
          <span
            >While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:</span
          >
        </p>
        <ul>
          <li><span> Email address</span></li>
          <li>
            <span>First name and last name</span>
          </li>
          <li><span>Phone number</span></li>
          <li>
            <span>Address, State, Province, ZIP/Postal code, City</span>
          </li>
          <li><span>Usage Data</span></li>
        </ul>
        <h3>
          <span>Usage Data</span>
        </h3>
        <p>
          <span
            >Usage Data is collected automatically when using the Service.</span
          >
        </p>
        <p>
          <span
            >Usage Data may include information such as Your Device&#39;s
            Internet Protocol address (e.g. IP address), browser type, browser
            version, the pages of our Service that You visit, the time and date
            of Your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.</span
          >
        </p>
        <p>
          <span
            >When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.</span
          >
        </p>
        <p>
          <span
            >We may also collect information that Your browser sends whenever
            You visit our Service or when You access the Service by or through a
            mobile device.</span
          >
        </p>
        <h3>
          <span>Information from Third-Party Social Media Services</span>
        </h3>
        <p>
          <span class="c7"
            >The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media
            Services:</span
          >
        </p>
        <ul class="c6 lst-kix_jagsypmpys6w-0 start">
          <li>
            <span>Google</span>
          </li>
          <li>
            <span>Facebook</span>
          </li>
          <li>
            <span>Twitter</span>
          </li>
        </ul>
        <p>
          <span
            >If You decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media
            Service&#39;s account, such as Your name, Your email address, Your
            activities or Your contact list associated with that account.</span
          >
        </p>
        <p>
          <span
            >You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service&#39;s
            account. If You choose to provide such information and Personal
            Data, during registration or otherwise, You are giving the Company
            permission to use, share, and store it in a manner consistent with
            this Privacy Policy.</span
          >
        </p>
        <h3>
          <span>Tracking Technologies and Cookies</span>
        </h3>
        <p>
          <span
            >We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:</span
          >
        </p>
        <ul>
          <li>
            <span
              >Cookies or Browser Cookies. A cookie is a small file placed on
              Your Device. You can instruct Your browser to refuse all Cookies
              or to indicate when a Cookie is being sent. However, if You do not
              accept Cookies, You may not be able to use some parts of our
              Service. Unless you have adjusted Your browser setting so that it
              will refuse Cookies, our Service may use Cookies.</span
            >
          </li>
          <li>
            <span
              >Flash Cookies. Certain features of our Service may use local
              stored objects (or Flash Cookies) to collect and store information
              about Your preferences or Your activity on our Service. Flash
              Cookies are not managed by the same browser settings as those used
              for Browser Cookies. For more information on how You can delete
              Flash Cookies, please read &quot;Where can I change the settings
              for disabling, or deleting local shared objects?&quot; available
              at </span
            ><span
              ><a
                href="https://www.google.com/url?q=https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html%23main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_&amp;sa=D&amp;source=editors&amp;ust=1621105765939000&amp;usg=AOvVaw3EobT4XIfoXXU5VisBCdIB"
                >https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_</a
              ></span
            >
          </li>
          <li>
            <span
              >Web Beacons. Certain sections of our Service and our emails may
              contain small electronic files known as web beacons (also referred
              to as clear gifs, pixel tags, and single-pixel gifs) that permit
              the Company, for example, to count users who have visited those
              pages or opened an email and for other related website statistics
              (for example, recording the popularity of a certain section and
              verifying system and server integrity).</span
            >
          </li>
        </ul>
        <p>
          <span
            >Cookies can be &quot;Persistent&quot; or &quot;Session&quot;
            Cookies. Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close Your web browser. Learn more about cookies: </span
          ><span
            ><a
              href="https://www.google.com/url?q=https://www.privacypolicies.com/blog/cookies/&amp;sa=D&amp;source=editors&amp;ust=1621105765940000&amp;usg=AOvVaw1171mgNfuj_154lMnCODUC"
              >What Are Cookies?</a
            ></span
          ><span>.</span>
        </p>
        <p>
          <span
            >We use both Session and Persistent Cookies for the purposes set out
            below:</span
          >
        </p>
        <ul>
          <li>
            <span
              >Necessary / Essential Cookies<br />Type: Session Cookies<br />Administered
              by: Us<br />Purpose: These Cookies are essential to provide You
              with services available through the Website and to enable You to
              use some of its features. They help to authenticate users and
              prevent fraudulent use of user accounts. Without these Cookies,
              the services that You have asked for cannot be provided, and We
              only use these Cookies to provide You with those services.</span
            >
          </li>
          <li>
            <span
              >Cookies Policy / Notice Acceptance Cookies<br />Type: Persistent
              Cookies<br />Administered by: Us<br />Purpose: These Cookies
              identify if users have accepted the use of cookies on the
              Website.</span
            >
          </li>
          <li>
            <span
              >Functionality Cookies<br />Type: Persistent Cookies<br />Administered
              by: Us<br />Purpose: These Cookies allow us to remember choices
              You make when You use the Website, such as remembering your login
              details or language preference. The purpose of these Cookies is to
              provide You with a more personal experience and to avoid You
              having to re-enter your preferences every time You use the
              Website.</span
            >
          </li>
        </ul>
        <p>
          <span
            >For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.</span
          >
        </p>
        <h2>
          <span>Use of Your Personal Data</span>
        </h2>
        <p>
          <span
            >The Company may use Personal Data for the following purposes:</span
          >
        </p>
        <ul>
          <li>
            <span
              >To provide and maintain our Service, including to monitor the
              usage of our Service.</span
            >
          </li>
          <li>
            <span
              >To manage Your Account: to manage Your registration as a user of
              the Service. The Personal Data You provide can give You access to
              different functionalities of the Service that are available to You
              as a registered user.</span
            >
          </li>
          <li>
            <span
              >For the performance of a contract: the development, compliance
              and undertaking of the purchase contract for the products, items
              or services You have purchased or of any other contract with Us
              through the Service.</span
            >
          </li>
          <li>
            <span
              >To contact You: To contact You by email, telephone calls, SMS, or
              other equivalent forms of electronic communication, such as a
              mobile application&#39;s push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.</span
            >
          </li>
          <li>
            <span
              >To provide You with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless You have opted not to receive such information.</span
            >
          </li>
          <li>
            <span
              >To manage Your requests: To attend and manage Your requests to
              Us.</span
            >
          </li>
          <li>
            <span
              >For business transfers: We may use Your information to evaluate
              or conduct a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of Our
              assets, whether as a going concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which Personal Data held by
              Us about our Service users is among the assets transferred.</span
            >
          </li>
          <li>
            <span
              >For other purposes: We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing
              and your experience.</span
            >
          </li>
        </ul>
        <p>
          <span
            >We may share Your personal information in the following
            situations:</span
          >
        </p>
        <ul>
          <li>
            <span
              >With Service Providers: We may share Your personal information
              with Service Providers to monitor and analyze the use of our
              Service, to contact You.</span
            >
          </li>
          <li>
            <span
              >For business transfers: We may share or transfer Your personal
              information in connection with, or during negotiations of, any
              merger, sale of Company assets, financing, or acquisition of all
              or a portion of Our business to another company.</span
            >
          </li>
          <li>
            <span
              >With Affiliates: We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.</span
            >
          </li>
          <li>
            <span
              >With business partners: We may share Your information with Our
              business partners to offer You certain products, services or
              promotions.</span
            >
          </li>
          <li>
            <span
              >With other users: when You share personal information or
              otherwise interact in the public areas with other users, such
              information may be viewed by all users and may be publicly
              distributed outside. If You interact with other users or register
              through a Third-Party Social Media Service, Your contacts on the
              Third-Party Social Media Service may see Your name, profile,
              pictures and description of Your activity. Similarly, other users
              will be able to view descriptions of Your activity, communicate
              with You and view Your profile.</span
            >
          </li>
          <li>
            <span
              >With Your consent: We may disclose Your personal information for
              any other purpose with Your consent.</span
            >
          </li>
        </ul>
        <h2>
          <span>Retention of Your Personal Data</span>
        </h2>
        <p>
          <span
            >The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.</span
          >
        </p>
        <p>
          <span
            >The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.</span
          >
        </p>
        <h2>
          <span>Transfer of Your Personal Data</span>
        </h2>
        <p>
          <span
            >Your information, including Personal Data, is processed at the
            Company&#39;s operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to &mdash; and maintained on &mdash;
            computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.</span
          >
        </p>
        <p>
          <span
            >Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.</span
          >
        </p>
        <p>
          <span
            >The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.</span
          >
        </p>
        <h2>
          <span>Disclosure of Your Personal Data</span>
        </h2>
        <h3>
          <span>Business Transactions</span>
        </h3>
        <p>
          <span
            >If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.</span
          >
        </p>
        <h3>
          <span>Law enforcement</span>
        </h3>
        <p>
          <span
            >Under certain circumstances, the Company may be required to
            disclose Your Personal Data if required to do so by law or in
            response to valid requests by public authorities (e.g. a court or a
            government agency).</span
          >
        </p>
        <h3>
          <span>Other legal requirements</span>
        </h3>
        <p>
          <span
            >The Company may disclose Your Personal Data in the good faith
            belief that such action is necessary to:</span
          >
        </p>
        <ul>
          <li>
            <span>Comply with a legal obligation</span>
          </li>
          <li>
            <span
              >Protect and defend the rights or property of the Company</span
            >
          </li>
          <li>
            <span
              >Prevent or investigate possible wrongdoing in connection with the
              Service</span
            >
          </li>
          <li>
            <span
              >Protect the personal safety of Users of the Service or the
              public</span
            >
          </li>
          <li>
            <span>Protect against legal liability</span>
          </li>
        </ul>
        <h2>
          <span>Security of Your Personal Data</span>
        </h2>
        <p>
          <span
            >The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.</span
          >
        </p>
        <h1>
          <span>Children&#39;s Privacy</span>
        </h1>
        <p>
          <span
            >Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our
            servers.</span
          >
        </p>
        <p>
          <span
            >If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent&#39;s consent before We collect and use that
            information.</span
          >
        </p>
        <h1>
          <span>Links to Other Websites</span>
        </h1>
        <p>
          <span
            >Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party&#39;s site. We strongly advise You to
            review the Privacy Policy of every site You visit.</span
          >
        </p>
        <p>
          <span
            >We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.</span
          >
        </p>
        <h1>
          <span>Changes to this Privacy Policy</span>
        </h1>
        <p>
          <span
            >We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this
            page.</span
          >
        </p>
        <p>
          <span
            >We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the
            &quot;Last updated&quot; date at the top of this Privacy
            Policy.</span
          >
        </p>
        <p>
          <span
            >You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.</span
          >
        </p>
        <h1>
          <span>Contact Us</span>
        </h1>
        <p>
          <span
            >If you have any questions about this Privacy Policy, You can
            contact us:</span
          >
        </p>
        <ul>
          <li>
            <span>By email: hello@5050.africa</span>
          </li>
        </ul>
        <p><span></span></p>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Privacy"
};
</script>

<style scoped></style>
