<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div @click="$router.push({ name: 'profile' })" style="cursor:pointer">
          <b-icon icon="arrow-left-circle" scale="1.5" variant="white"></b-icon>
          <span class="ml-3 text-white">Back</span>
        </div>
        <div class="text-center text-white pt-4">
          Produces
        </div>
      </div>
      <div class="card-body">
        <b-alert
          v-model="showDismissibleSuccessAlert"
          variant="success"
          dismissible
        >
          Success!
        </b-alert>
        <produce-list
          @removeProduce="removeSelectedProduct"
          @addProduce="addSelectedProduct"
          :preSelected="selectedProduct"
        ></produce-list>
        <b-form inline @submit.prevent="updateUserProduce">
          <div
            v-for="produce in selectedProduct"
            class="text-left mb-3 col-md-12"
            :key="produce.name"
          >
            <b-form-input
              v-model="produce.name"
              class="mr-2 "
              disabled
            ></b-form-input>
            <datetime
              class="mr-2 form-control"
              type="datetime"
              :input-class="{
                'border-0': true,
                'mt-n2': true
              }"
              required
              v-model="produce.date_available"
              placeholder="Date available"
              style="background-color: transparent"
            ></datetime>
            <b-form-input
              v-model="produce.quantity_available"
              required
              placeholder="Quantity available"
            ></b-form-input>
          </div>
          <b-button type="submit" variant="primary" class="text-left  mb-3"
            >Update</b-button
          >
        </b-form>
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-control {
  font-size: 0.9rem;
}
</style>
<script>
import axios from "axios";
import { mapState } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import ProduceList from "@/components/partials/ProduceList";

export default {
  name: "ProduceToBeSold",
  components: {
    Datetime,
    ProduceList
  },
  data() {
    return {
      selectedProduct: [],
      showDismissibleAlert: false,
      showDismissibleSuccessAlert: false
      // alreadySelected: []
    };
  },
  created() {
    axios
      .get("user/produces", {
        headers: { Authorization: "Bearer " + this.token }
      })
      .then(res => {
        this.selectedProduct = this.formatSelectedProduces(res.data);
      });
  },
  computed: {
    ...mapState({
      token: "tokenBearer"
    })
  },
  methods: {
    addSelectedProduct: function(produce) {
      this.selectedProduct.push({
        id: produce.id,
        name: produce.name,
        date_available: "",
        quantity_available: ""
      });
    },
    removeSelectedProduct: function(produce) {
      let selected = this.selectedProduct.findIndex(
        el => el.produce_id === produce.id
      );
      this.selectedProduct.splice(selected, 1);
    },
    updateUserProduce: function() {
      for (let obj of this.selectedProduct) {
        if (!this.validateProduceField(obj)) {
          this.showDismissibleAlert = true;
          return false;
        }
      }
      var vm = this;
      axios
        .post(
          "user/farmer/produces/update",
          {
            produces: vm.selectedProduct
          },
          { headers: { Authorization: "Bearer " + this.token } }
        )
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    },
    validateProduceField(val) {
      return !!(val.name && val.quantity_available && val.date_available);
    },
    formatSelectedProduces(values) {
      let selected = [];
      for (let val of values) {
        selected.push({
          id: val.id,
          name: val.name,
          quantity_available: val.pivot.quantity_available,
          date_available: val.pivot.date_available
        });
      }
      return selected;
    }
  }
};
</script>

<style scoped></style>
