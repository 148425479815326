<template>
  <div>
    <navigation></navigation>
    <div class="mt-5"></div>
    <router-view />
  </div>
</template>

<script>
import Navigation from "@/components/partials/Navigation";
import store from "@/store";
import router from "@/router";
// import store from "@/store";
// import router from "@/router";

export default {
  name: "App",
  components: { Navigation },
  mounted() {
    this.$store.commit("isAuth");
    if (store.state.isAuth === true) router.push({ name: "dashboard" });
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
