<template>
  <div>
    <b-card
      header-bg-variant="primary"
      header="What would you like to do?"
      header-class="text-white p-5"
      img-top
      tag="article"
      class="text-center m-2"
    >
      <b-card-text class="text-center">
        <!--        Would you like to buy or sell ?-->
      </b-card-text>
      <div class="text-center">
        <b-button
          href="#"
          variant="primary"
          class="d-block mb-4"
          @click="updateQuestionAnswered('buy-or-sell-produce')"
          >Sell or Buy Produce
        </b-button>
        <b-button
          href="#"
          variant="primary"
          class="d-block mb-4"
          @click="updateQuestionAnswered('buy-or-sell-land')"
          >Get or Give Access to Land</b-button
        >
        <b-button
          href="#"
          variant="primary"
          class="d-block mb-4"
          @click="updateQuestionAnswered('final-step', 'ask-expert-advice')"
          >Get Expert Advice</b-button
        >
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "StepOne",
  data() {
    return {
      questions: {
        produce: [{ location: "", products: [] }]
      }
    };
  },
  created() {
    this.$emit("clearQuestionsAnswered");
  },
  props: {
    answeredQuestion: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateQuestionAnswered: function(name, type) {
      if (!type) {
        this.$emit("updateQuestion", name);
        this.$router.push({ name: name });
      } else {
        this.saveAns(type);
      }
    },

    saveAns: function(answer) {
      let answ = {};
      answ["type"] = answer;
      this.$router.push({ name: "final-step", params: answ });
    }
  }
};
</script>

<style scoped></style>
