<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="light">
      <b-navbar-brand :to="{ name: 'index' }">
        <img
          src="https://5050.africa/wp-content/uploads/2020/01/5050-logo.png"
          width="60"
        />
      </b-navbar-brand>

      <b-navbar-toggle
        target="nav-collapse"
        class="bg-primary"
      ></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item :to="{ name: 'login' }" v-if="!isLoggedIn"
              >Login</b-nav-item
            >
            <b-nav-item :to="{ name: 'step-one' }" v-if="!isLoggedIn"
              >Register</b-nav-item
            >
            <b-nav-item :to="{ name: 'dashboard' }" v-if="isLoggedIn"
              >Home</b-nav-item
            >
            <b-nav-item :to="{ name: 'profile' }" v-if="isLoggedIn"
              >Profile</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'farm' }"
              v-if="isLoggedIn && userIntent.slug in farmerIntent"
              >Farm</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'produce-to-bought' }"
              v-if="isLoggedIn && userIntent.id === 1"
              >Produce</b-nav-item
            >
            <b-nav-item
              :to="{ name: 'produce-to-sold' }"
              v-if="isLoggedIn && userIntent.slug === 'sell-produce'"
              >Produce</b-nav-item
            >
            <b-nav-item v-if="isLoggedIn" @click="logout">Logout</b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Navigation",
  computed: {
    ...mapState({
      isLoggedIn: "isAuth",
      loggedInUser: "user",
      userIntent: "userIntent",
      farmerIntent: "farmerIntentList"
    })
  },
  methods: {
    logout: function() {
      this.$store.commit("unauthUser");
      this.redirectAfterLogout();
    },
    redirectAfterLogout: function() {
      this.$router.push({ name: "login" });
    }
  },
  watch: {
    loggedInUser: function(value) {
      if (value) {
        this.$store.commit("getUserIntent");
      }
    }
  }
};
</script>

<style scoped></style>
