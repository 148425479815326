import { render, staticRenderFns } from "./FarmerPagesLayout.vue?vue&type=template&id=2adf8482&scoped=true&"
import script from "./FarmerPagesLayout.vue?vue&type=script&lang=js&"
export * from "./FarmerPagesLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2adf8482",
  null
  
)

export default component.exports