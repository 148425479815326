<template>
  <div>
    <b-table striped hover :items="items" :fields="fields"></b-table>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "SellLandList",
  data() {
    return {
      items: [],
      fields: [
        "location_town",
        "price",
        "is_debushed",
        "water_irrigation",
        "size"
      ]
    };
  },
  created() {
    var vm = this;
    axios
      .get("/user/lands", {
        headers: { Authorization: "Bearer " + this.token }
      })
      .then(res => {
        vm.items = res.data;
      });
  },
  computed: {
    ...mapState({
      token: "tokenBearer"
    })
  }
};
</script>

<style scoped></style>
