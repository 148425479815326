<template>
  <div>
    <b-row class="justify-content-center">
      <div class="col-md-8 col-sm-12 col-12">
        <router-view></router-view>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import router from "@/router";

export default {
  name: "GeneralUserPageLayout",
  mounted() {
    //ToDo :: This is to be cleaned up
    if (store.state.isAuth === false) router.push({ name: "index" });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(vm.userIntent.id);
      if (vm.isLoggedIn && [1, 3, 4].indexOf(vm.userIntent.id) !== -1) {
        next;
      } else if (vm.isLoggedIn) {
        next({ name: "profile" });
      } else {
        next({ name: "login" });
      }
    });
  },
  computed: {
    ...mapState({
      isLoggedIn: "isAuth",
      loggedInUser: "user",
      userIntent: "userIntent"
    })
  }
};
</script>

<style scoped></style>
