<template>
  <div>
    <div class="card">
      <div class="card-header bg-primary pl-3 pr-5 pb-5">
        <div class="text-center text-white pt-4">
          Land information
        </div>
      </div>
      <div class="card-body">
        <form action="#" method="post" @submit.prevent="saveLand">
          <b-form-group
            id="input-group-1"
            label="Location"
            label-for="input-1"
            class="text-left"
          >
            <b-form-input
              id="input-2"
              v-model="form.location_town"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Price"
            label-for="input-3"
            class="text-left"
          >
            <b-form-input
              id="input-3"
              required
              v-model="form.price"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-3"
            label="Land size (km)"
            label-for="input-5"
            class="text-left"
          >
            <b-form-input
              type="number"
              id="input-4"
              v-model="form.size"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Land Soil Test"
            class="text-left"
          >
            <b-form-radio v-model="form.is_debushed" :value="1" required
              >Yes</b-form-radio
            >
            <b-form-radio v-model="form.is_debushed" :value="0" required
              >No</b-form-radio
            >
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Water irrigation"
            class="text-left"
          >
            <b-form-radio v-model="form.water_irrigation" :value="1" required
              >Yes</b-form-radio
            >
            <b-form-radio v-model="form.water_irrigation" :value="0" required
              >No</b-form-radio
            >
          </b-form-group>
          <b-form-group
            id="input-location"
            label="Borehole water"
            class="text-left"
          >
            <b-form-radio v-model="form.borehole_water" :value="1" required
              >Yes</b-form-radio
            >
            <b-form-radio v-model="form.borehole_water" :value="0" required
              >No</b-form-radio
            >
          </b-form-group>
          <b-form-group id="input-location" label="Fenced" class="text-left">
            <b-form-radio v-model="form.is_fenced" :value="1" required
              >Yes</b-form-radio
            >
            <b-form-radio v-model="form.is_fenced" :value="0" required
              >No</b-form-radio
            >
          </b-form-group>

          <b-button type="submit" variant="primary" class="text-left  mb-3"
            >Save</b-button
          >
        </form>
      </div>
      <b-alert variant="success" show dismissible v-show="showDismissibleAlert"
        >New land saved successfully</b-alert
      >
      <b-alert variant="danger" show dismissible v-show="error"
        >Something went wrong</b-alert
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "SellLand",
  data() {
    return {
      form: {
        price: "",
        size: "",
        is_debushed: "",
        location_town: "",
        borehole_water: "",
        water_irrigation: "",
        is_fenced: ""
      },
      showDismissibleAlert: false,
      error: false
    };
  },
  mounted() {
    // var vm = this;
    // axios
    //   .get("/user/farm", {
    //     headers: { Authorization: "Bearer " + this.token }
    //   })
    //   .then(res => {
    //     vm.farm = res.data;
    //   });
  },
  methods: {
    saveLand: function() {
      var vm = this;
      axios
        .post("/user/land/store", vm.form, {
          headers: { Authorization: "Bearer " + this.token }
        })
        .then(res => {
          vm.showDismissibleAlert = true;
          vm.resetForm();
          console.log(res);
        })
        .catch(err => {
          vm.error = true;
          console.log(err);
        });
    },
    resetForm: function() {
      this.form.size = "";
      this.form.price = "";
      this.form.is_fenced = "";
      this.form.is_debushed = "";
      this.form.location_town = "";
      this.form.borehole_water = "";
      this.form.water_irrigation = "";
    }
  },
  computed: {
    ...mapState({
      token: "tokenBearer"
    })
  }
};
</script>

<style scoped></style>
