<template>
  <div>
    <b-form-group id="input-group-4" label="Select produce" label-for="input-4">
      <multiselect
        :options="produces"
        label="name"
        track-by="id"
        v-model="selected"
        :multiple="true"
        @remove="removeProduce"
        @select="addProduce"
      ></multiselect>
    </b-form-group>
    <link
      rel="stylesheet"
      href="https://unpkg.com/vue-multiselect@2.1.0/dist/vue-multiselect.min.css"
    />
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
export default {
  name: "ProduceList",
  components: {
    Multiselect
  },
  data() {
    return {
      selected: null,
      produces: []
    };
  },
  props: {
    preSelected: {
      required: true,
      type: Array
    }
  },
  created() {
    axios.get("produces").then(res => {
      this.produces = res.data;
    });

    setTimeout(() => {
      this.mapNamesPropsOnly(this.preSelected);
    }, 1000);
  },

  methods: {
    mapNamesPropsOnly: function(values) {
      console.log("hey");
      let arrs = [];
      for (let selected of values) {
        arrs.push({ name: selected.name, id: selected.id });
      }
      this.selected = arrs;
    },
    removeProduce(value) {
      this.$emit("removeProduce", value);
    },
    addProduce: function(value) {
      this.$emit("addProduce", value);
    }
  }
};
</script>

<style scoped></style>
