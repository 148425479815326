<template>
  <div>
    <b-row class="justify-content-center">
      <div class="col-md-4 col-sm-6 col-12">
        <router-view
          :answeredQuestion="answeredQuestion"
          @updateQuestion="updateQuestion"
          @clearQuestionsAnswered="clearAnswers"
        ></router-view>
      </div>
    </b-row>
  </div>
</template>

<script>
import routeGuardMixins from "@/mixins/routeGuardMixins";

export default {
  name: "QuestionLayout",
  mixins: [routeGuardMixins],
  data() {
    return {
      answeredQuestion: []
    };
  },
  methods: {
    updateQuestion: function(val) {
      this.answeredQuestion.push(val);
      console.log("hey");
      console.log(this.answeredQuestion);
    },
    clearAnswers: function() {
      this.answeredQuestion = [];
    }
  }
};
</script>

<style scoped></style>
