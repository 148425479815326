<template>
  <div class="hello">
    <div class="mb-5">
      <h1>Welcome</h1>
      <h3>5050 Africa Farmers App</h3>
    </div>
    <p class="mb-5">
      5050 Africa is an app designed for farmers, by farmers. Get free access to
      agronomists for expert advise, as well as access to buyers for your
      produce.
    </p>
    <div class="justify-content-center">
      <b-button
        variant="primary"
        class="mr-2"
        :to="{ name: 'step-one' }"
        tag="span"
        >Join us</b-button
      >
      <b-button variant="primary" :to="{ name: 'login' }">Login</b-button>
    </div>
    <div class="mt-5">
      <span class="font-weight-bold">Value Proposition</span>
      <p>
        Farmers (primary user): Access. Access to knowledge, experts, inputs,
        market (sales). Produce Buyers (secondary user): Access to produce at
        great value Land owners (Other users): sell or rent your land Gov, NGO,
        data scientists…
      </p>
    </div>
    <div>
      <blockquote>
        <span class="font-italic">
          …designed by farmers (farmers co-create, co-design) the app
        </span>
      </blockquote>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
