import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import QuestionLayout from "@/components/QuestionLayout";
import StepOne from "@/components/questions/StepOne";
import SignUpStep from "@/views/SignUpStep";
import BuyOrSellProduce from "@/components/questions/Produce/BuyOrSellProduce";
import BuyProduce from "@/components/questions/Produce/BuyProduce";
import BuyOrSellLands from "@/components/questions/Lands/BuyOrSellLands";
import BuyLand from "@/components/questions/Lands/BuyLand";
import Login from "@/views/Login";
import HomeLayout from "@/components/HomeLayout";
import Profile from "@/views/Profile";
import FarmerPagesLayout from "@/components/FarmerPagesLayout";
import FarmProfile from "@/components/farmer/FarmProfile";
import GeneralUserPageLayout from "@/components/GeneralUserPageLayout";
import ProduceToBeBought from "@/views/ProduceToBeBought";
import ProduceToBeSold from "@/views/ProduceToBeSold";
import Privacy from "@/views/Privacy";
import Dashboard from "@/views/Dashboard";
import store from "@/store/index";
import AskAdvice from "@/views/AskAdvice";
import SellLand from "@/views/SellLand";
import SellLandList from "@/views/SellLandList";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Home,
    beforeEnter: (to, from, next) => {
      store.state.isAuth === true ? next({ name: "dashboard" }) : next();
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      store.state.isAuth === true ? next({ name: "dashboard" }) : next();
    }
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy
  },
  {
    path: "/welcome",
    component: QuestionLayout,
    children: [
      {
        path: "/step-one",
        component: StepOne,
        name: "step-one"
      },
      {
        path: "/final-step",
        component: SignUpStep,
        name: "final-step",
        props: true
      },
      {
        path: "/buy-or-sell-produce",
        component: BuyOrSellProduce,
        name: "buy-or-sell-produce"
      },
      {
        path: "/buy-produce",
        component: BuyProduce,
        name: "buy-produce"
      },

      {
        path: "/buy-or-sell-land",
        component: BuyOrSellLands,
        name: "buy-or-sell-land"
      },

      {
        path: "/buy-land",
        component: BuyLand,
        name: "buy-land"
      }
    ]
  },
  {
    path: "/home",
    component: HomeLayout,
    children: [
      {
        path: "dashboard",
        component: Dashboard,
        name: "dashboard",
        beforeEnter: (to, from, next) => {
          store.state.isAuth === true ? next() : next({ name: "login" });
        }
      },
      {
        path: "profile",
        component: Profile,
        name: "profile",
        beforeEnter: (to, from, next) => {
          store.state.isAuth === true ? next() : next({ name: "login" });
        }
      },
      {
        path: "sell-land",
        component: SellLand,
        name: "new-land",
        beforeEnter: (to, from, next) => {
          store.state.isAuth === true ? next() : next({ name: "login" });
        }
      },
      {
        path: "sell-land/lists",
        component: SellLandList,
        name: "land-for-sell-list",
        beforeEnter: (to, from, next) => {
          store.state.isAuth === true ? next() : next({ name: "login" });
        }
      }
    ]
  },
  {
    path: "/farmer",
    component: FarmerPagesLayout,
    children: [
      {
        path: "info",
        component: FarmProfile,
        name: "farm"
      },
      {
        path: "produce",
        component: ProduceToBeSold,
        name: "produce-to-sold"
      },
      {
        path: "ask-expert",
        component: AskAdvice,
        name: "ask-expert"
      }
    ]
  },
  {
    path: "/user",
    component: GeneralUserPageLayout,
    children: [
      {
        path: "produce",
        component: ProduceToBeBought,
        name: "produce-to-bought"
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if (to.path.indexOf("/home/") !== -1) {
//     store.state.isAuth === true ? next() : next({ name: "login" });
//   } else if (to.path.indexOf("/farmer/") !== -1) {
//     store.state.isAuth === true ? next() : next({ name: "login" });
//   } else next();
// });

export default router;
